import React, { ChangeEvent, useState } from "react";
import HeaderComponent from "../../../components/Header/HeaderComponent";
import InputComponent from "../../../components/Input/InputComponent";
import DropZone from "../../../components/DropZone/DropZone";
import { SimpleSearchParams } from "../../../hooks/SearchParams";
import { Api, PostFileApi } from "../../../services/Api";
import { ProductType } from "../../../types/Products";
import { AxiosError, AxiosResponse } from "axios";
import { IsAdministrator } from "../../../services/Utils";
interface creationType {
  description: string;
  name: string;
  marketplaceName: string;
  manufacturingValue: string;
  acquisitionValue: string;
  standardAmount: string;
  originalManufacturingValue: number;
}
const CreateProduct = () => {
  let [loading, setLoading] = useState(false);
  let [product, setProduct] = useState<creationType>({
    description: "",
    name: "",
    marketplaceName: "",
    manufacturingValue: "",
    acquisitionValue: "",
    standardAmount: "",
    originalManufacturingValue: 0,
  });
  let [ImgFile, setImgFile] = useState<File>();
  let [errorMessage, setErrorMessage] = useState<string>();
  let SearchParams = SimpleSearchParams();
  const handleChange = (
    element: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    let target = element.currentTarget;
    setProduct({ ...product, [target.id]: target.value });
  };
  const handleSubmit = async () => {
    if (
      !ImgFile ||
      !product.acquisitionValue ||
      !product.manufacturingValue ||
      !product.name ||
      !product.standardAmount ||
      !product.description
    )
      return setErrorMessage(
        "Todos os campos são obrigatórios",
      );

    let formData = new FormData();

    formData.append("file", ImgFile);
    formData.append("name", product.name);
    if (product.marketplaceName)
      formData.append(
        "marketplaceName",
        product.marketplaceName,
      );
    formData.append("description", product.description);
    formData.append(
      "acquisitionValue",

      (Number(product.acquisitionValue) * 100).toFixed(0) + ""
    );
    formData.append(
      "manufacturingValue",
      (Number(product.manufacturingValue) * 100).toFixed(0) + ""
    );
    formData.append(
      "standardAmount",
      (Number(product.standardAmount) * 100).toFixed(0) + ""
    );
    formData.append(
      "originalManufacturingValue",
      (Number(product.originalManufacturingValue) * 100).toFixed(0) + ""

    );
    setLoading(true);
    let response: AxiosResponse<ProductType> =
      await PostFileApi("/products", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => res)
        .catch((err) => {
          setErrorMessage(
            "Ocorreu um erro na criação do produto",
          );
          console.error(err);
          return err;
        });
    setLoading(false);
    if (response.status === 201) {
      SearchParams.delete("modal");
    } else {
      setErrorMessage(
        "Ocorreu um erro na criação do produto",
      );
    }
  };

  return (
    <main id="createProduct">
      <HeaderComponent
        title={
          IsAdministrator() ? "Criar Produto" : "Produto"
        }
      />
      {errorMessage && (
        <h2
          className="error"
          style={{
            paddingLeft: ".5rem",
            fontSize: "1.5rem",
          }}
        >
          {errorMessage}
        </h2>
      )}
      <section>
        <div>
          <InputComponent
            id="name"
            label="Nome:"
            type="text"
            onChange={handleChange}
            required
            error={!product.name}
          />
          <InputComponent
            id="marketplaceName"
            label="Nome Marketplace:"
            type="text"
            onChange={handleChange}
            required
            // error={!product.marketplaceName}
          />
          <div className="manufacturing">
            <InputComponent
              id="originalManufacturingValue"
              label="Custo de Produção:"
              type="number"
              step="0.01"
              min="0"
              value={product.originalManufacturingValue}
              onChange={handleChange}
              error={!product.originalManufacturingValue}
            />
            <InputComponent
              id="manufacturingValue"
              label="Valor de venda fábrica:"
              type="number"
              step="0.01"
              min="0.01"
              onChange={handleChange}
            />
          </div>
          <div className="values">
            <InputComponent
              id="acquisitionValue"
              label="Valor de aquisição:"
              type="number"
              step="0.01"
              min="0.01"
              onChange={handleChange}
              required
              error={!product.acquisitionValue}
            />
            <span>
              <p>Lucro da fabrica: </p>
              <p>
                R$
                {(
                  Number(product.manufacturingValue) -
                  Number(product.originalManufacturingValue)
                ).toFixed(2)}
              </p>
            </span>
          </div>
          <div className="values">
            <InputComponent
              id="standardAmount"
              label="Valor sugerido:"
              type="number"
              step="0.01"
              min="0.01"
              onChange={handleChange}
              required
              error={!product.standardAmount}
            />
            <span>
              <p style={{ marginLeft: "1rem" }}>
                Lucro da loja:{" "}
              </p>
              <p>
                R$
                {(
                  Number(product.standardAmount) -
                  Number(product.acquisitionValue)
                ).toFixed(2)}
              </p>
            </span>
          </div>
          <div
            id="descricaoProduto"
            style={{ margin: "4px" }}
          >
            <label htmlFor="description">Descrição</label>
            <textarea
              style={{
                // ...(product?.description?{}:{border: "1px solid #e94b4b"}),
                width: "100%",
                borderRadius: "8px",
                backgroundColor: "#EDEDEF",
                marginTop: "4px",
                padding: "8px",
              }}
              onChange={handleChange}
              value={product.description}
              name="description"
              id="description"
              rows={5}
            ></textarea>
          </div>
        </div>
        <div id="dropzone-container">
          <DropZone
            message="Selecione a imagem do produto"
            onChange={setImgFile}
            accept="image/png,image/jpg,image/jfif,image/jpeg"
            imagePreview
          />
        </div>
      </section>
      <footer>
        <button
          className="second"
          onClick={() => SearchParams.delete("modal")}
        >
          Cancelar
        </button>
        <button
          disabled={
            !ImgFile ||
            !product.acquisitionValue ||
            !product.manufacturingValue ||
            !product.name ||
            !product.standardAmount ||
            loading
          }
          onClick={handleSubmit}
        >
          {loading ? "Carregando" : "Criar"}
        </button>
      </footer>
    </main>
  );
};

export default CreateProduct;
