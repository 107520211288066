import { Axios, AxiosRequestConfig, AxiosResponse } from "axios";

const getToken = () => window.localStorage.getItem("userToken");

const Api = new Axios({
  baseURL:
    process.env.REACT_APP_URL ||
    "https://ma25jc38g0.execute-api.us-east-1.amazonaws.com/dev/feather-shoes/",
  responseType: "json",
  transformResponse: (data) => {
    if (!data) return data;
    return JSON.parse(`${data}`);
  },

  headers: {
    Authorization: `Bearer ${getToken()}`
  }
});
const RefreshToken = async () => {
  if (!window.localStorage.getItem("refreshToken")) {
    window.location.href = "/"
    return
  }
  let res = await PostApi<{ idToken: string }>("/refresh-token", {
    refreshToken: window.localStorage.getItem("refreshToken"),
  })
  if (res.status === 200) {
    window.localStorage.setItem("userToken", res.data.idToken);
    window.localStorage.removeItem("refreshToken");
    // window.document.location.reload()
    return
  } else if (res.status === 400) {
    localStorage.clear()
    window.location.pathname = '/'
    return
  }
  await RefreshToken()
};


export const GetApi = <T = unknown>(
  URL: string,
  config?: AxiosRequestConfig
) => {
  return new Promise<AxiosResponse<T>>((resolve, reject) => {
    Api.get<T>(URL, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      ...config,
    })
      .then(async (response) => {
        let { status } = response;

        if (status === 401) {
          RefreshToken();
          return await GetApi<T>(URL, config).then(resolve).catch(reject);
        }
        resolve(response);
      })
      .catch(async (err) => {
        await RefreshToken();
        reject(err);
      });
  });
};

export const PostApi = <T = unknown>(
  URL: string,
  data: object,
  config?: AxiosRequestConfig, Content_Type?: 'text/html' | 'text/plain' | 'multipart/form-data' | 'application/json' | 'application/x-www-form-urlencoded' | 'application/octet-stream'
) => {
  return new Promise<AxiosResponse<T>>((resolve, reject) => {
    Api.post<T>(URL, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': Content_Type || 'application/json'
      },
      ...config,
    })
      .then(async (Response) => {
        let { status } = Response;
        if (status === 401) {
          await RefreshToken();
          return await PostApi<T>(URL, data, config)
            .then(resolve)
            .catch(reject);
        }
        resolve(Response);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

export const PutApi = <T = unknown>(
  URL: string,
  data: object,
  config?: AxiosRequestConfig
) => {
  return new Promise<AxiosResponse<T>>((resolve, reject) => {
    Api.put<T>(URL, JSON.stringify(data), {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      ...config,
    })
      .then(async (Response) => {
        let { status } = Response;
        if (status === 401) {
          await RefreshToken();
          return await PutApi<T>(URL, data, config).then(resolve).catch(reject);
        }
        resolve(Response);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
}; export const PutFileApi = <T = unknown>(
  URL: string,
  data: object,
  config?: AxiosRequestConfig
) => {
  return new Promise<AxiosResponse<T>>((resolve, reject) => {
    Api.put<T>(URL, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      ...config,
    })
      .then(async (Response) => {
        let { status } = Response;
        if (status === 401) {
          await RefreshToken();
          return await PutApi<T>(URL, data, config).then(resolve).catch(reject);
        }
        resolve(Response);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};
export const PostFileApi = <T = unknown>(
  URL: string,
  data: object,
  config?: AxiosRequestConfig,
) => {
  return new Promise<AxiosResponse<T>>((resolve, reject) => {
    Api.post<T>(URL, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      ...config,
    })
      .then(async (Response) => {
        let { status } = Response;
        if (status === 401) {
          await RefreshToken();
          return await PostApi<T>(URL, data, config)
            .then(resolve)
            .catch(reject);
        }
        resolve(Response);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};
export const DeleteApi = <T = unknown>(
  URL: string,
  data?: object,

  config?: AxiosRequestConfig
) => {
  return new Promise<AxiosResponse<T>>((resolve, reject) => {
    Api.delete<T>(URL, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
      ...config,
    })
      .then(async (Response) => {
        let { status } = Response;
        if (status === 401) {
          await RefreshToken();
          return await DeleteApi<T>(URL, data, config)
            .then(resolve)
            .catch(reject);
        }
        resolve(Response);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

export { Api }